import { useEffect } from "react";

function Home() {
  useEffect(() => {
    // Redirect to /youtube.html
    window.location.replace("/youtube.html");
  }, []);

  return null; // This component won't render anything as it immediately redirects
}

export default Home;
