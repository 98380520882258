// src/components/Navbar.js
import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { ReactComponent as DiffSenseLogo } from "../assets/diff-sense.svg";
import "./Navbar.css";

const NavigationBar = () => {
  return (
    <Navbar variant="dark" expand="lg" className="custom-navbar">
      <Container fluid className="mx-4">
        <Navbar.Brand as={Link} to="/diffsense">
          <DiffSenseLogo className="navbar-logo" />
          <div className="ms-2">Diff Sense</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto custom-nav-links">
            {/* <Nav.Link as={Link} to="/product">
              Product
            </Nav.Link> */}
            <Nav.Link href="mailto:jack@3plus1.ai">Help</Nav.Link>
            {/* <Nav.Link as={Link} to="/pricing">
              Pricing
            </Nav.Link> */}
            <Nav.Link href="https://x.com/3plus1_ai" target="_blank">
              Twitter
            </Nav.Link>
            <Button
              className="navbar-button"
              as={Link}
              to="/diffsensetool"
              variant="primary"
              style={{
                backgroundColor: "#2f33de",
                borderColor: "#2f33de",
                color: "white",
                borderRadius: "4px",
              }}>
              Start Diffing
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
