import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ReactComponent as DiffSenseLogo } from "../assets/diff-sense.svg";
import { useNavigate } from "react-router-dom";

function DiffSensePromo() {
  const [imageHeight, setImageHeight] = useState("auto");

  useEffect(() => {
    const updateImageHeight = () => {
      const headerHeight = document.querySelector(".mb-5").offsetHeight;
      const availableHeight = window.innerHeight - headerHeight - 400; // Subtract additional margins
      setImageHeight(`${availableHeight}px`);
    };

    updateImageHeight();
    window.addEventListener("resize", updateImageHeight);

    return () => window.removeEventListener("resize", updateImageHeight);
  }, []);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/diffsensetool");
  };

  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center justify-content-center text-center bg-black text-white vh-100">
      <Row className="mb-4">
        <Col md={8} className="d-flex justify-content-center">
          <DiffSenseLogo
            style={{
              maxWidth: "100px",
              height: "auto",
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col>
          <h1 className="mb-3 fw-bold">Aesthetic LLM Response Comparisons</h1>
          <h2 className="mb-5 text-secondary">
            Discover subtle differences in GenAI text. <br /> Prompt comparisons
            made effortless & insightful
          </h2>
          <Button
            variant="primary"
            size="lg"
            className="mb-1"
            onClick={handleButtonClick}>
            Try DiffSense for free
          </Button>
          <p className="text-secondary">
            Completely Private. <br /> We never log any of your data.
          </p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-4">
        <Col>
          <img
            src="https://i.postimg.cc/YSxgzsnb/foo.png"
            alt="Diff Sense Screenshot"
            className="img-fluid shadow"
            style={{
              borderRadius: "15px",
              height: imageHeight,
              width: "auto",
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default DiffSensePromo;
