// src/components/DiffViewer.js
import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import { Form, Row, Col } from "react-bootstrap";

class DiffViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "dark",
      splitView: true,
      syntaxHighlighting: true,
      customGutter: true,
      lineNumbers: true,
      compareMethod: DiffMethod.WORDS,
    };
  }

  handleThemeChange = () => {
    this.setState((prevState) => ({
      theme: prevState.theme === "dark" ? "light" : "dark",
    }));
  };

  handleToggle = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  handleCompareMethodChange = (event) => {
    this.setState({ compareMethod: event.target.value });
  };

  syntaxHighlight = (str) => {
    if (!str) return;
    const language = Prism.languages.javascript; // Adjust this based on the data type
    const highlightedCode = Prism.highlight(str, language, "javascript");
    return <span dangerouslySetInnerHTML={{ __html: highlightedCode }} />;
  };

  render() {
    const { text1, text2 } = this.props;

    return (
      <div className={`app-container ${this.state.theme}`}>
        <header className="text-center my-4">
          <h3>Text Diff Viewer</h3>
        </header>

        <div className="options mt-4">
          <Form>
            <Row>
              <Col md={4}>
                <Form.Check
                  type="switch"
                  id="dark-theme-switch"
                  label="Dark Theme"
                  checked={this.state.theme === "dark"}
                  onChange={this.handleThemeChange}
                  className="mb-3 fs-3"
                />
              </Col>
              <Col md={4}>
                <Form.Check
                  type="switch"
                  id="split-view-switch"
                  label="Split Pane"
                  checked={this.state.splitView}
                  onChange={() => this.handleToggle("splitView")}
                  className="mb-3 fs-3"
                />
              </Col>
              <Col md={4}>
                <Form.Check
                  type="switch"
                  id="syntax-highlighting-switch"
                  label="Syntax Highlighting"
                  checked={this.state.syntaxHighlighting}
                  onChange={() => this.handleToggle("syntaxHighlighting")}
                  className="mb-3 fs-3"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Check
                  type="switch"
                  id="custom-gutter-switch"
                  label="Custom Gutter"
                  checked={this.state.customGutter}
                  onChange={() => this.handleToggle("customGutter")}
                  className="mb-3 fs-3"
                />
              </Col>
              <Col md={4}>
                <Form.Check
                  type="switch"
                  id="line-numbers-switch"
                  label="Line Numbers"
                  checked={this.state.lineNumbers}
                  onChange={() => this.handleToggle("lineNumbers")}
                  className="mb-3 fs-3"
                />
              </Col>
              <Col md={4}>
                <Form.Group className="d-inline-flex align-items-center mb-3">
                  <Row>
                    <Col md={5}>
                      <Form.Label className="fs-3 mb-0 me-2 ">
                        Diff Method
                      </Form.Label>
                    </Col>
                    <Col md={7}>
                      {" "}
                      <Form.Select
                        value={this.state.compareMethod}
                        onChange={this.handleCompareMethodChange}
                        className="fs-4 ">
                        <option value={DiffMethod.CHARS}>Characters</option>
                        <option value={DiffMethod.WORDS}>Words</option>
                        <option value={DiffMethod.WORDS_WITH_SPACE}>
                          Words with Space
                        </option>
                        <option value={DiffMethod.LINES}>Lines</option>
                        <option value={DiffMethod.TRIMMED_LINES}>
                          Trimmed Lines
                        </option>
                        <option value={DiffMethod.SENTENCES}>Sentences</option>
                        <option value={DiffMethod.CSS}>CSS</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>

        <ReactDiffViewer
          oldValue={text1}
          newValue={text2}
          splitView={this.state.splitView}
          compareMethod={this.state.compareMethod}
          renderContent={
            this.state.syntaxHighlighting ? this.syntaxHighlight : undefined
          }
          useDarkTheme={this.state.theme === "dark"}
          hideLineNumbers={!this.state.lineNumbers}
        />
      </div>
    );
  }
}

export default DiffViewer;
