// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CsvUploader from "./components/CsvUploader";
import Home from "./components/Home";
import NavigationBar from "./components/Navbar"; // Import the NavigationBar component
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import DiffSensePromo from "./components/DiffSensePromo";

function App() {
  return (
    <Router>
      <NavigationBar /> {/* Include the NavigationBar here */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/diffsense" element={<DiffSensePromo />} />
        <Route path="/diffsensetool" element={<CsvUploader />} />
      </Routes>
    </Router>
  );
}

export default App;
