export const defaultRows = [
  {
    text1:
      "The quick brown fox jumps over the lazy dog. This sentence is commonly used to demonstrate fonts and test typing equipment.",
    text2:
      "The quick brown fox leaps over the sleepy dog. This phrase is often used as a typing drill or font example.",
    cosineSim: 0.7,
  },
  {
    text1:
      "# Markdown Example\n## Subheader\nThis is a header with a list:\n- Item 1\n- Item 2\n\nThis is some **bold** text with `inline code`.",
    text2:
      "# Markdown Example\n## Subheader Updated\nThis is a header with an updated list:\n- Item 1\n- Item A\n\nThis is some *italic* text with `inline code changed`.",
    cosineSim: 0.7,
  },
  {
    text1:
      "def add(a, b):\n    '''\n    Adds two numbers together\n    '''\n    return a + b\n\nresult = add(10, 5)\nprint(f'The result is {result}')",
    text2:
      "def add(a, b):\n    '''\n    Adds two numbers together and then doubles the result\n    '''\n    return 2 * (a + b)\n\nresult = add(10, 5)\nprint(f'The doubled result is {result}')",
    cosineSim: 0.7,
  },
  {
    text1:
      '{\n  "name": "John",\n  "age": 30,\n  "address": {\n    "street": "123 Elm St",\n    "city": {\n      "name": "Gotham",\n      "district": {\n        "name": "Downtown",\n        "subDistrict": {\n          "name": "Central",\n          "areaCode": "001"\n        }\n      }\n    },\n    "zip": "12345"\n  },\n  "phoneNumbers": ["123-456-7890", "987-654-3210"]\n}',
    text2:
      '{\n  "name": "John",\n  "age": 31,\n  "address": {\n    "street": "124 Elm St",\n    "city": {\n      "name": "Metropolis",\n      "district": {\n        "name": "Uptown",\n        "subDistrict": {\n          "name": "North Side",\n          "areaCode": "002"\n        }\n      }\n    },\n    "zip": "54321"\n  },\n  "phoneNumbers": ["123-456-7890", "111-222-3333"]\n}',
    cosineSim: 0.7,
  },
  {
    text1:
      "name: John Doe\nage: 30\naddress:\n  street: 123 Elm St\n  city:\n    name: Gotham\n    district:\n      name: Downtown\n      subDistrict:\n        name: Central\n        areaCode: 001\n  zip: 12345\nphoneNumbers:\n  - 123-456-7890\n  - 987-654-3210",
    text2:
      "name: John Doe\nage: 31\naddress:\n  street: 124 Elm St\n  city:\n    name: Metropolis\n    district:\n      name: Uptown\n      subDistrict:\n        name: North Side\n        areaCode: 002\n  zip: 54321\nphoneNumbers:\n  - 123-456-7890\n  - 111-222-3333",
    cosineSim: 0.7,
  },
];
