import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import {
  Button,
  Table,
  Alert,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DiffViewer from "./DiffViewer";
import { defaultRows } from "../defaultData";
import { Row, Col } from "react-bootstrap";
import "./fadeout.css"; // Include your fade-out CSS
import { pipeline, cos_sim, env } from "@xenova/transformers";

env.allowLocalModels = false;
env.useBrowserCache = false;

export const computeCosineSim = async (a, b) => {
  const extractor = await pipeline(
    "feature-extraction",
    "Xenova/jina-embeddings-v2-base-en",
    { quantized: false }
  );

  // Generate embeddings
  const output = await extractor([a, b], { pooling: "mean" });

  // Compute cosine similarity
  return cos_sim(output[0].data, output[1].data);
};

function CsvUploader() {
  const [rows, setRows] = useState(defaultRows); // Initialize with default data
  const [selectedRow, setSelectedRow] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(""); // State to track upload feedback
  const [fadeOut, setFadeOut] = useState(false); // State to handle fade out animation
  const [columns, setColumns] = useState([]); // To store the column headers
  const [sourceColumn, setSourceColumn] = useState("text1"); // Default source column
  const [hypothesisColumn, setHypothesisColumn] = useState("text2"); // Default hypothesis column

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const parsedColumns = Object.keys(results.data[0]); // Get the column headers
        setColumns(parsedColumns);
        setRows(results.data);
        setUploadMessage(`${file.name} has been successfully uploaded.`);
        setFadeOut(false); // Reset fade out animation if a new file is uploaded

        // Automatically set the first and second columns as source and hypothesis
        setSourceColumn(parsedColumns[0]); // Set first column as source
        setHypothesisColumn(parsedColumns[1]); // Set second column as hypothesis
      },
    });
  };

  useEffect(() => {
    if (uploadMessage) {
      const fadeTimer = setTimeout(() => {
        setFadeOut(true);
      }, 2000); // Start fading after 2 seconds

      const removeMessageTimer = setTimeout(() => {
        setUploadMessage("");
      }, 3000); // Completely remove after 3 seconds

      // Cleanup timeouts
      return () => {
        clearTimeout(fadeTimer);
        clearTimeout(removeMessageTimer);
      };
    }
  }, [uploadMessage]);

  const handleDiffClick = (rowIndex) => {
    setSelectedRow(rowIndex);
  };

  const computeMetrics = async () => {
    const updatedRows = await Promise.all(
      rows.map(async (row) => {
        try {
          const similarity = await computeCosineSim(
            row[sourceColumn || "text1"],
            row[hypothesisColumn || "text2"]
          );
          return { ...row, cosineSim: similarity.toFixed(3) };
        } catch (error) {
          console.error("Error computing cosine similarity:", error);
          return { ...row, cosineSim: "err" };
        }
      })
    );
    setRows(updatedRows);
  };

  return (
    <div className="m-4">
      <div className="csv-uploader p-4 rounded">
        <Row className="align-items-center mb-4">
          <Col md="3">
            <h2 className="mb-0 text-white">Upload CSV File</h2>
          </Col>
          <Col md="3" className="text-end">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Select a CSV file to upload and analyze differences.
                </Tooltip>
              }>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                className="btn btn-primary bg-primary border-0"
                aria-label="Upload CSV file"
              />
            </OverlayTrigger>
          </Col>
        </Row>

        {uploadMessage && (
          <Alert
            variant="success"
            className={`mt-3 fade-out ${fadeOut ? "hide" : ""}`}>
            {uploadMessage}
          </Alert>
        )}

        {columns.length > 0 && (
          <Row className="mb-4">
            <Col md="6">
              <Form.Group controlId="sourceColumn">
                <Form.Label className="fs-4">Select Source Column</Form.Label>
                <Form.Control
                  as="select"
                  value={sourceColumn}
                  onChange={(e) => setSourceColumn(e.target.value)}
                  aria-label="Select source column"
                  className="fs-5">
                  <option value="">Select Source Column</option>
                  {columns.map((col, index) => (
                    <option key={index} value={col}>
                      {col}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="hypothesisColumn">
                <Form.Label className="fs-4">
                  Select Hypothesis Column
                </Form.Label>
                <Form.Control
                  as="select"
                  value={hypothesisColumn}
                  onChange={(e) => setHypothesisColumn(e.target.value)}
                  aria-label="Select hypothesis column"
                  className="fs-5">
                  <option value="">Select Hypothesis Column</option>
                  {columns.map((col, index) => (
                    <option key={index} value={col}>
                      {col}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Button
          variant="primary"
          onClick={computeMetrics}
          className="mb-4"
          aria-label="Compute cosine similarity for all rows">
          Compute Metrics
        </Button>

        {rows.length > 0 && (
          <Table hover variant="dark" className="mt-4 rounded shadow-sm">
            <thead className="bg-primary text-white">
              <tr className="text-center">
                <th>#</th>
                <th>Source</th>
                <th>Hypothesis</th>
                <th>CosineSim</th>
                <th>Diff</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className="fs-5 text-center align-middle">{index + 1}</td>
                  <td className="align-middle">
                    {row[sourceColumn || "text1"].substring(0, 50)}...
                  </td>
                  <td className="align-middle">
                    {row[hypothesisColumn || "text2"].substring(0, 50)}...
                  </td>
                  <td className="fs-5 text-center align-middle">
                    {row.cosineSim !== undefined ? row.cosineSim : "-"}
                  </td>
                  <td className="d-flex justify-content-center align-items-center">
                    <Button
                      variant="primary"
                      onClick={() => handleDiffClick(index)}
                      className="btn-sm fw-bold fs-5"
                      aria-label={`View differences for row ${index + 1}`}>
                      View Diff
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {selectedRow !== null && (
          <div className="mt-4">
            <DiffViewer
              text1={rows[selectedRow][sourceColumn || "text1"]}
              text2={rows[selectedRow][hypothesisColumn || "text2"]}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CsvUploader;
